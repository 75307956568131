import { ThemeContext } from "../ThemeContext";
import "./Homepage.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import { useContext } from "react";
import { FaDiscord, FaTwitter, FaLinkedin } from "react-icons/fa";

const Footer=()=>{
  const { toggleTheme, isDarkMode } = useContext(ThemeContext);
    return(
      <div className={isDarkMode ?"" : "light-mode"}>
        <div className="">
        <footer className="py-3 my-4 ">
          <ul className="nav justify-content-center border-bottom pb-3 mb-3">
            <li className="nav-item">
              <a href="/" className="nav-link px-2 homepage-nav-item-font me-3">
                FAQs
              </a>
            </li>
            <li className="nav-item">
              <a href="/" className="nav-link px-2 homepage-nav-item-font me-3">
                Blog
              </a>
            </li>
            <li className="nav-item">
              <a href="/" className="nav-link px-2 homepage-nav-item-font me-3">
                Whitepaper
              </a>
            </li>
            <li className="nav-item">
              <a href="/" className="nav-link px-2 homepage-nav-item-font me-3">
                Docs
              </a>
            </li>
            <li className="nav-item">
              <a href="/" className="nav-link px-2 homepage-nav-item-font me-3">
                Github
              </a>
            </li>
            <li className="nav-item">
              <a href="/" className="nav-link px-2 homepage-nav-item-font me-3">
                Press
              </a>
            </li>
            <li className="nav-item">
              <a href="/" className="nav-link px-2 homepage-nav-item-font me-3">
                <FaDiscord />
              </a>
            </li>
            <li className="nav-item">
              <a href="/" className="nav-link px-2 homepage-nav-item-font me-3">
                <FaTwitter />
              </a>
            </li>
            <li className="nav-item">
              <a href="/" className="nav-link px-2 homepage-nav-item-font me-3">
                <FaLinkedin />
              </a>
            </li>
          </ul>
          <p className="text-center homepage-nav-item-font-bold">
            Kaanch Network © 2023
          </p>
          <div className="d-flex justify-content-center">
            <span className="homepage-nav-item-font-small">
              Terms and Conditions
            </span>
            <span className="ms-3 below-content homepage-nav-item-font-small">
              Privacy and Policy
            </span>
          </div>
        </footer>
        </div>
        </div>
      
   )
}
export default Footer;