import React, { useContext, useEffect, useRef, useState } from 'react';
import { FaArrowRightArrowLeft } from "react-icons/fa6";
import { FaCircle } from "react-icons/fa";
import { IoIosArrowDown } from "react-icons/io";
import { IoMdSwap } from "react-icons/io";
import { IoIosArrowUp } from "react-icons/io";

import { IoMdArrowDropdown } from "react-icons/io";

//<For Dark mode and white mode--------------------------------->
import { ThemeContext } from "../ThemeContext";
import "./Homepage.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import "./BlockdetailsPage.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { FaDiscord, FaTwitter, FaLinkedin } from "react-icons/fa";
import Footer from './Footer';


const TransferKaanch = () => {
  //For the Dark and white mode----------------------------------->
  const {  isDarkMode } = useContext(ThemeContext);
  //<-----------------------End of the Dark and white Mode--->
  const [whenNotConnectSelect,setWhenNotConnectSelect]=useState()
  const [currentNetwork, setCurrentNetwork] = useState('');

  const [NetworkIdNameWhenConnect,setNetworkIdNameWhenConnect]=useState()
  const [selected,setSelected]=useState()
  
//<-----------------For Kaanch account variable----------------------------------->  
//<-----------------For Kaanch account variable----------------------------------->  
const [retrieved,setRetrievedAddress]=useState()
let [data, setData]=useState([])
//<---------------------------------end---kanch--->

const [contractsArray,setcontractsArray]=useState([])

console.log("contract array",contractsArray)

//<------------------------For Kaanch account connect------------------------------->

//<--For Kaanch Balance----------------->
const [kaanchBalance,setKaanchbalance]=useState()
const [contractHex,setContractHex]=useState()

//<-For se sufficent button will show or not----->
const [CanTransferBalance,setCanBeTranserBalance]=useState(0)

//<For logo of sorceToken--------------------------------->
const [selectedmetaToken,setselectmetaToken]=useState("Select")

//<--------------------------end of logo>

//<For Input balance------------------->
const [inputBalance,setInputBalance]=useState(0)

//For the  other wallet connect using chain Id-------------------------->
const ChainIdOject=[{name:'Ethereum'},{name:'Binance'},{name:'Polygon'}]

//For meta mask wallet connect address--------------------------->
const [balance, setBalance] = useState(null);

const [logselectedOtherWallet,setlogoSelectOtherwallet]=useState()
const [ethBalance,setEthBalance]=useState()

const [selectContractAdress,setSelectContractAddress]=useState()
const[tokenDetails,settokendata]=useState()
///<-----------------------------For when we disconnect then select option change//


//For Second Address Polygon,Binance,Ethereum Network Image


useEffect(() => {
    const timer = setTimeout(() => {
      checkKaanchConnection();
      
      
      
     
    }, 100);
    return () => clearTimeout(timer);
  }, []);

  const checkKaanchConnection = async () => {
    try {
      if (window.kaanch) {
        const connected = await window.kaanch.isConnected();
      
        if (connected) {
          const Kaanchaddress = await window.kaanch.account();
          setRetrievedAddress(Kaanchaddress)
          
        }
      } else {
        console.error("kaanch object not found on window  please install kaanch wallet");
       
      }
    } catch (error) {
      console.error("Error checking Kaanch connection: please install", error);
      
    }
  };

 
  
  const retrieveAddress = async () => {
    if (window.kaanch && window.kaanch.connect) {
        
      const retrieved = await window.kaanch.connect();
      setRetrievedAddress(retrieved);

   
    } else {
      alert("The function window.kaanch.connect() is not available.");
    }
  };

  //-----------For Kaanch disconnect-------------------------->
  const disconnectFromKaanch = async () => {
    if (window.kaanch && window.kaanch.disconnect) {
      window.kaanch.disconnect();
      setRetrievedAddress("");
    } else {
      alert("The function window.kaanch.disconnect() is not available.");
    }
  };

  //<For fetching the data------------------------------------------>
   //<For fetching the data------------------------------------------>
   const API="https://verified-token-api.kaanch.network/"
   const fetchdata=async(url)=>{
     try {
         const fetchdata=await fetch(url)
         const data=await fetchdata.json()
             setData(data)
             
      } catch (error) {
       console.error(error)
         }
     }
 
 
   useEffect(()=>{
       fetchdata(API);
       
      
   },[]) 

     

   //For the fetching balance

   const fetchBalanceKanch=async()=>{
    try {
      const response = await fetch(`https://data.all-balance.kaanch.network/?address=${retrieved}`);
    const allBalance = await response.json();
      console.log(allBalance)

    const balanceNumber = parseInt(allBalance.balance, 16) / Math.pow(10, 18);
     if(retrieved){ setKaanchbalance(balanceNumber)}  //for kaanch balance
    
    console.log("all balance",allBalance)
    setcontractsArray(allBalance.contracts)
    console.log("contract address",contractsArray)
    } catch (error) {
      console.error(error)

    }
    
  }
  

  const [contractdecimal,setContractdecimal]=useState()


  useEffect(()=>{fetchBalanceKanch()},[retrieved])


  async  function fetchToken(selectContractA){
      
    await fetch("https://dev-testnet-01.kaanch.network/", {
       method: "POST",
       headers: {
         "Content-Type": "application/json",
       },
       body: JSON.stringify({
         "jsonrpc": "2.0",
         "method": "kaanch_tokenDetails",
         "params": [selectContractA],
         "id": 1
       })
 
     }).then(res => res.json())
       .then((data) => {
         //console.log("data", data)
        
          settokendata(data.result.tokenData);
          setContractdecimal(data.result.tokenData.decimals)


       })
   }

   console.log(selectContractAdress)
   console.log(data)

useEffect(()=>{
  fetchToken(selectContractAdress)
},[selectContractAdress])
console.log("token",tokenDetails)
console.log("tokendecimal",contractdecimal)
  //<----------------------------------------------end of fetching data>

 
  ///<For showing menu option of Token source---------------------------->

  const [isClicked, setIsClicked] = useState(localStorage.getItem('isClicked') === 'true');

  useEffect(() => {
    localStorage.setItem('isClicked', isClicked);
  }, [isClicked]);

  const [isDisabled, setIsDisabled] = useState(true);
  const handleShow = () => {
    setIsClicked(prevState => !prevState);
    setIsDisabled(prev=>!prev);
  };
  
 
  //<-------------------------------end of swapping toggle>

  //<For getting the symbol respect to token name--------------------------------------------->
  const [tokenKaanch,setTokenKaanch]=useState( window.localStorage.getItem("kanchToken"))

  const [logoSelectedVal,setlogoSelectedVal]=useState(window.localStorage.getItem("kaanchLogoToken"))
  

   async function  symbolHandle(e){
    let TokenNameSelected= e
    let selectedRowOfData = data.filter(function (val) {
      return TokenNameSelected === val.symbol
    })

   setlogoSelectedVal(selectedRowOfData[0].logo)
   window.localStorage.setItem("kaanchLogoToken",selectedRowOfData[0].logo)
   setTokenKaanch(selectedRowOfData[0].symbol)
   window.localStorage.setItem("kanchToken",selectedRowOfData[0].symbol)
  setSelectContractAddress(selectedRowOfData[0].contract_address)
 // fetchToken(selectedRowOfData[0].contract_address);

  
const getBalanceOfContract = (contractaddr) => {

  for (let {balance,contract} of contractsArray) {
     
      if (contract===contractaddr) {
         
          return balance;
      }
  }

  return null;
};



const balanceOfTargetContract = getBalanceOfContract(selectedRowOfData[0].contract_address);
console.log("cotractbalance",balanceOfTargetContract)
setContractHex(balanceOfTargetContract)
console.log(balanceOfTargetContract)
if(balanceOfTargetContract)
{
  let balanceToken=parseInt(balanceOfTargetContract, 16) /Math.pow(10,contractdecimal)
  console.log(balanceToken)
  setKaanchbalance(balanceToken)
}
else{
  setKaanchbalance(0)
}

console.log("contract_decimal",contractdecimal)

{/*
if (balanceOfTargetContract !== null) {

  let tokenBalance=parseInt(`${balanceOfTargetContract}`, 16) /Math.pow(10,2)
     if(retrieved){ setKaanchbalance(tokenBalance)}
} else {
  console.log(`Contract ${selectedRowOfData[0].contract_address} not found.`);
  setKaanchbalance(0)
}
  */
}
  
    }
   
 

  //<For getting the symbol for otherWallet------------------>
  const [secWalletToken,setSecTokenWallet]=useState()
  const[TokenListImage,setTokenListImage]=useState()
  function symbolHandleOtherWallet(e){
    
   
     setSecTokenWallet(e)
     window.localStorage.setItem("secTokenValueSet",e)
     console.log(window.localStorage.getItem("secTokenValueSet"))
    if(window.localStorage.getItem("secTokenValueSet")==='ETH'){
      window.localStorage.setItem("secTokenImage","https://logowik.com/content/uploads/images/ethereum3649.jpg")
    }
    else if(window.localStorage.getItem("secTokenValueSet")==='POLY'){
      window.localStorage.setItem("secTokenImage","https://altcoinsbox.com/wp-content/uploads/2023/03/matic-logo.webp")
    }
    else if(window.localStorage.getItem("secTokenValueSet")==='PNCH'){
      window.localStorage.setItem("secTokenImage","https://verified-token-image-api.kaanch.network/6714b6bc-816a-47db-c985-888bfe895600")
    }
    else if(window.localStorage.getItem("secTokenValueSet")==='BNB'){
      window.localStorage.setItem("secTokenImage","https://seeklogo.com/images/B/binance-coin-bnb-logo-97F9D55608-seeklogo.com.png")
    }
    else if(window.localStorage.getItem("secTokenValueSet")==='CRD'){
      window.localStorage.setItem("secTokenImage","https://cdn4.iconfinder.com/data/icons/crypto-currency-and-coin-2/256/cardano_ada-512.png")
    }
    else if(window.localStorage.getItem("secTokenValueSet")==='NTT'){
      window.localStorage.setItem("secTokenImage",'https://verified-token-image-api.kaanch.network/726b2a0d-bbea-4cfa-865d-27d48289e500')
    }
   


  
     
  }

 
  //<---------------------------end of getting of other symbol--------->
//<For Source Balance---------------------------->








useEffect(()=>{
  max();
},[isClicked])
const max=()=>{
  if(!isClicked){
    setInputBalance(kaanchBalance)
  }
  else{
    setInputBalance(0)
  }

}
//------------------------end Source Blance-->

//<--For imput Amount Change is it sufficent or not -------------------------------------->
useEffect(() => {

  if(parseFloat(inputBalance)<=parseFloat(kaanchBalance))
{
  setCanBeTranserBalance(true)
}
else{
  setCanBeTranserBalance(false)
}
}, [ inputBalance]);
//<----------------------------end of Amount changes-->



  const changeAmount = (e) => {
    const newBalance = e.target.textContent;
    setInputBalance(newBalance);
    // You can perform additional logic here if needed
  };

const [inputBalance2,setInputBalance2]=useState()
  function changeAmount2(e) {
    const numberValue = parseFloat(e.target.value) || 0;
   
      setInputBalance2(numberValue);
  
  
    
  
    }




//<------------------------------------------------------------------------For metamask-------->

const [isConnected, setIsConnected] = useState(false);
const [account, setAccount] = useState('');

useEffect(() => {
  // Check if MetaMask is installed
  if (window.ethereum) {
    // Add event listener to handle chainChanged and accountsChanged
    window.ethereum.on('chainChanged', handleChainChanged);
    window.ethereum.on('accountsChanged', handleAccountsChanged);

    // Check if already connected
    checkConnection();
  }
}, []);

useEffect(() => {
  // Update local storage when connection status changes
  localStorage.setItem('isConnected', isConnected);
}, [isConnected]);

const checkConnection = async () => {
  try {
    const accounts = await window.ethereum.request({ method: 'eth_accounts' });
    if (accounts.length > 0) {
     
      setIsConnected(true);
      setAccount(accounts[0]);
    }
  } catch (error) {
    console.error('Error checking connection:', error);
  }
};

const connectToMetaMask = async () => {
  try {
    await window.ethereum.request({ method: 'eth_requestAccounts' });
      if('Ethereum'===whenNotConnectSelect){
       // switchNetwork('0x1')
        await window.ethereum.request({
          method: 'wallet_switchEthereumChain',
          params: [{ chainId: '0x1' }],
        });
        
      }
      else if('Binance'===whenNotConnectSelect)
      {
        await window.ethereum.request({
          method: 'wallet_switchEthereumChain',
          params: [{ chainId: '0x38' }],
        });
       // switchNetwork('0x38')
      }
       
        
      else if('Polygon'===whenNotConnectSelect)
      {
        //switchNetwork('0x89');
        await window.ethereum.request({
          method: 'wallet_switchEthereumChain',
          params: [{ chainId: '0x89' }],
        });
      }

    
        
        

     
    
  } catch (error) {
    console.error('Error connecting to MetaMask:', error);
  }
};




const handleAccountsChanged = (accounts) => {
  // Handle accounts changed event
  console.log('Accounts changed:', accounts);
  if (accounts.length === 0) {
    setIsConnected(false);
    setAccount('');
  } else {
    setIsConnected(true);
    setAccount(accounts[0]);
  }
};

const disconnectFromMetaMask = async() => {
  setIsConnected(false);
  setAccount('');
  localStorage.setItem('isConnected', false);
  await window.ethereum.request({
    "method": "wallet_revokePermissions",
    "params": [
      {
        "eth_accounts": {}
      }
    ]
  });
};



const handleOptionChangeSymbol=(optionNameTwo)=>{
  setselectmetaToken(optionNameTwo) 
  setIsActiveToken(false)
  symbolHandle(optionNameTwo)
}


const [isActivemetaToken,setisActivemetaToken]=useState()
const handleOptionChangeSymbolMeta=(option)=>{
  setisActivemetaToken(false)
  setselectmetaToken(option)
  symbolHandleOtherWallet(option)

}



const handleOptionChangenotSwitch=(optionNamenot)=>{
  setSelected(optionNamenot);
  setIsActive(false);
}
//<---------------------------end of metamask from net------------------------------>

///Fetching the balance from metamask ------------------------------------------>


useEffect(() => {
  // Check if MetaMask is installed
  if (window.ethereum) {
    // Check if already connected
    checkConnection();
  }
}, []);

useEffect(() => {
  // Fetch balance when account changes
  if (account) {
    fetchBalance2FromMetamask(account);
  }
}, [account]);





const fetchBalance2FromMetamask = async (account) => {
  try {
    const balance = await window.ethereum.request({
      method: 'eth_getBalance',
      params: [account, 'latest'],
    });

    // Convert balance from Wei to Ether
    const balanceInEther = window.ethereum.utils.fromWei(balance, 'ether');
    //setBalance(balanceInEther);
    setEthBalance(balanceInEther)
    setInputBalance2(balanceInEther)
  } catch (error) {
    console.error('Error fetching balance:', error);
  }
};

//<end of fetching the balance from metamask---------------------------->



//<-------------------------------end of connect metamask


//For when network will chnage that function will run------------------->




const SwithchNetwork = async (e) =>{
  const valTarget=e

  
  if(isConnected && isClicked){disconnectFromMetaMask()}


  setWhenNotConnectSelect(valTarget)
  console.log(valTarget)
  switch (valTarget) {
      case "Ethereum":
        switchNetwork('0x1')
        localStorage.setItem("lastImageChanged","//w7.pngwing.com/pngs/268/1013/png-transparent-ethereum-eth-hd-logo-thumbnail.png")
          break;
      case "Binance":
        switchNetwork('0x38')
        localStorage.setItem("lastImageChanged","https://upload.wikimedia.org/wikipedia/commons/thumb/e/e8/Binance_Logo.svg/768px-Binance_Logo.svg.png")
          break 
      case "Polygon":
        switchNetwork('0x89');
        localStorage.setItem("lastImageChanged","https://i.pinimg.com/474x/9b/1e/97/9b1e977d00b5d887608b156705a10759.jpg")
        break;
  }
  }     


  const handleOptionChange = (optionName) => {
    setSelected(optionName);
    setIsActive(false);
    // Call your onChange method with the selected option
    
    SwithchNetwork(optionName);
    
  };
 // We recommend reloading the page, unless you must do otherwise.




   

//<---For Kanch to Other Wallet Transfer--------------------------->
//<--------sending kaanch to Other Account---------->
async function transfer() {
  await window.kaanch.sendKaanch(inputBalance, account)
};
//<-----------------------------End of Kaanch to other wallet Transfer


  

///<--------------------------------------------------For switch ing from checking 



useEffect(() => {
  // Check if MetaMask is installed
  if (window.ethereum) {
    // Add event listener to handle chainChanged
    window.ethereum.on('chainChanged', handleChainChanged);
   
    

    // Check if already connected
    checkConnection();
  }
    return () => {
      // Remove event listener when component unmounts
      if (window.ethereum) {
        window.ethereum.removeListener('chainChanged', handleChainChanged);
      }
    };
  
}, []);





const getCurrentNetwork = async () => {
  try {
    const networkId = await window.ethereum.request({ method: 'eth_chainId' });
   if(account.length>0){
    setCurrentNetwork(getNetworkName(networkId));
   }else{
    setCurrentNetwork(whenNotConnectSelect)
   } 
   
    if(networkId==='0x1'){
      localStorage.setItem("lastImageChanged","//w7.pngwing.com/pngs/268/1013/png-transparent-ethereum-eth-hd-logo-thumbnail.png")

    }
    else if(networkId==='0x38')
    {
      localStorage.setItem("lastImageChanged","https://upload.wikimedia.org/wikipedia/commons/thumb/e/e8/Binance_Logo.svg/768px-Binance_Logo.svg.png")
      
    }
    else if(networkId==='0x89'){
      localStorage.setItem("lastImageChanged","https://i.pinimg.com/474x/9b/1e/97/9b1e977d00b5d887608b156705a10759.jpg ")
    }
  } catch (error) {
    console.error('Error fetching current network:', error);
  }
};
useEffect(() => {
  // Fetch current network when component mounts
  if (window.ethereum) {
    getCurrentNetwork();
  }
}, [currentNetwork]);
//const [isRefreshed, setIsRefreshed] = useState(false);

const handleChainChanged = (chainId) => {
    if(account.length>0){
      setCurrentNetwork(getNetworkName(chainId));
    }
    else{
      setCurrentNetwork(whenNotConnectSelect)
    }
    
  

};


//-
const [tokenList,setTokenList]=useState([{}])
useEffect(()=>{
  
},[])

const getNetworkName = (chainId) => {
  switch (chainId) {
    case '0x1':
      setTokenList([{"symbol":"ETH"},{"symbol":"CRD"}])
      setNetworkIdNameWhenConnect('Ethereum');
    
      return 'Ethreum';
    case '0x38':
      setTokenList([{"symbol":"BNB"},{"symbol":"NTT"}])
      setNetworkIdNameWhenConnect('Binance');
      return 'Binance';
    case '0x89':
      setTokenList([{"symbol":"POLY"},{"symbol":"PNCH"}])
      setNetworkIdNameWhenConnect('Polygon');
      return 'Polygon';
    default:
      setTokenList([{"symbol":"KNCH"},{"symbol":"ETH"},{"symbol":"PNCH"},{"symbol":"NTT"}])
      return 'Unknown Network';
  }
};



//const [ChainIdChangedConnect,setChainChangedConnect]=useState(true)
const switchNetwork = async (networkId) => {
  getNetworkName(networkId)
  try {
    if(account.length>0 && isClicked){
    await window.ethereum.request({
      method: 'wallet_switchEthereumChain',
      params: [{ chainId: networkId }],
    });
  }


   
    
    window.localStorage.setItem("secTokenImage"," ")
    
  } catch (error) {
    console.error('Error switching network:', error);
  }
};





///--------------For dropdown

const [isActive,setIsActive]=useState()

const options=['Reacr','Vue', 'Angular']


const [isActiveToken,setIsActiveToken]=useState()
const [selectedToken,setSelectedToken]=useState("Select")










    return (
      
        
      <div className={isDarkMode ? "dark-mode-homepage " : "light-mode-homepage"}>
        <div className='homepage-bg-color'>
        <div className='py-10  homepage-bg-color'>
        <div className='color'>gjjgjjg</div>
        <p className='color'>fhhhfhf</p>

        <p className='color pt-3'></p>

        {/**--------------------------- */}
        <div className='border color w-full max-w-xl  m-auto  rounded-lg items-center flex-col p-4  shadow-md box  '>

          {/*First Div----------------------------- */}
          <div className='border rounded-lg shadow-md  box '>
            {!isClicked?(<div className=' md:text-xl  flex  arrowRotate p-3 firstDiv'>
              {/**FRom --------------- */}
              <div>
                <div className='mx-1 font-bold'> From</div>
                <div className=' border rounded-md shadow-sm items-center justify-between mx-2 w-56  p-2'>
                    <div className='flex '>
                    {retrieved?(<div className='pt-2'><img className='w-3 h-4 '  alt='' src='https://lh3.googleusercontent.com/o7Et8UWumSlhaI3vyoGVpndwV851TTTnxqw0LxBO35wsBjzuTdtxeGZTEa01qNeLwDSN5gavpjzRShgOdbG5dNxHrg=s60'/></div>):(<div className='mt-1 px-2'><FaCircle/></div>)}
                    <div className='font-bold'>Kaanch</div>
                    </div>
                    
                </div>
              </div>
              {/**End of From---------------- */}
              <div className='mt-4 py-3 px-2 cursor-pointer rotatearrow' onClick={handleShow}><IoMdSwap /></div>
              {/****To start------------------ */}


              <div>
              <div className='mx-2 font-bold'> To</div>
                       <div className='flex  border rounded-md shadow-sm items-center justify-between w-56 p-2 dropdownNew relative'>
        <div className='flex dropdown-btnNew  '   onClick={() => setIsActive(!isActive)}   >
          <div className=' px-2'>{account?(<div className=' mr-2 rounded-full '><img className='w-5 h-6  rounded-full' alt='' src={localStorage.getItem("lastImageChanged")}/></div>):(<div className=''><FaCircle /></div>)}</div>
          <div>{whenNotConnectSelect||currentNetwork} </div>
        </div>
        <div><IoMdArrowDropdown /></div>
      </div>

      {isActive && (
        <div className='dropdown-itemNew  cursor-pointer shadow-sm gap-2  absolute bg-white' >
          {ChainIdOject.map((option) => (
            <div key={option.name}  onClick={() => handleOptionChange(option.name)}>
              <div className='dropdown-itemNew cursor-pointer hover:bg-sky-500 border'>{option.name}</div>
            </div>
          ))}
        </div>
      )}
                
              </div>
              {/*end of To start---------------------------- */}
            </div>):(<div className=' md:text-xl  flex  p-3 firstDiv arrowRotate'>
              {/**FRom --------------- */}
              <div>
                <div className='mx-1 font-bold'> From</div>
                <div className='flex border rounded-md shadow-sm items-center justify-between w-56 p-2 dropdownNew'>
        <div className='flex dropdown-btnNew' onClick={() => setIsActive(!isActive)}>
        <div className=' px-2'>{account?(<div className=' mr-2 rounded-full '><img className='w-5 h-6  rounded-full' alt='' src={localStorage.getItem("lastImageChanged")}/></div>):(<div className=''><FaCircle /></div>)}</div>
          <div>{whenNotConnectSelect||currentNetwork}</div>
        </div>
        <div><IoMdArrowDropdown /></div>
      </div>

      {isActive && (
        <div className='dropdown-itemNew  cursor-pointer shadow-sm gap-2  absolute bg-white' >
          {ChainIdOject.map((option) => (
            <div key={option.name}  onClick={() => handleOptionChange(option.name)}>
              <div className='dropdown-itemNew cursor-pointer hover:bg-sky-500 border'>{option.name}</div>
            </div>
          ))}
        </div>
      )}
              </div>
              {/**End of From---------------- */}
              <div className='mt-4 py-3 px-2 cursor-pointer rotatearrow' onClick={handleShow}><IoMdSwap /></div>
              {/****To start------------------ */}
              <div>
              <div className='mx-2 font-bold'> To</div>
              <div className=' border rounded-md shadow-sm items-center justify-between mx-2 w-56  p-2'>
              <div className='flex '>
                    {retrieved?(<div className='pt-2'><img className='w-3 h-4 ' alt='' src='https://lh3.googleusercontent.com/o7Et8UWumSlhaI3vyoGVpndwV851TTTnxqw0LxBO35wsBjzuTdtxeGZTEa01qNeLwDSN5gavpjzRShgOdbG5dNxHrg=s60'/></div>):(<div className='mt-1 px-2'><FaCircle/></div>)}
                    <div className='font-bold'>Kaanch</div>
                    </div>

</div>
              </div>
              {/*end of To start---------------------------- */}
            </div>)}
          </div>
          {/*End of First Div--------------------------------------------- */}

          {/*SEcond div start----------------- */}
          <div className='border rounded-lg shadow-md py-2 mt-4 box '>
            {!isClicked?(<div className=' text-xl flex-col mx-3 '>
              <div className='flex justify-between px-2'>
                  <div>Source token</div>
                  {retrieved?(<div className='text-xl text-red-500 borderConnect rounded-2xl px-4 cursor-pointer hover:text-red-900' onClick={disconnectFromKaanch}>Disonnect</div>):(<div className='text-xl text-red-500 borderConnect rounded-2xl px-4 cursor-pointer hover:text-red-900' onClick={retrieveAddress}>Connect</div>)}
              </div>

              <div className='flex '>
              <div className='py-1 px-2'>
                          <div className='flex border rounded-md shadow-sm items-center justify-between  p-2 dropdownNew relative'  >
                          <div className='w-5 h-7 pt-1 ' >{retrieved?(<div><img className='rounded-full' src={logoSelectedVal}/></div>):(<div><FaCircle /></div>)}</div>
                          
                       
 
                    <div className='flex dropdown-btnNew' onClick={()=>setIsActiveToken(!isActiveToken)}   >
                 
                    <div  className='px-2'>{tokenKaanch || window.localStorage.getItem("lastSymbol")}</div>
                    </div>
                    <div className='' ><IoMdArrowDropdown /></div>
                </div>

                {isActiveToken&&
            <div className=' cursor-pointer  absolute    bg-white   '  >


            {data.map((options)=>(
              <div  key={options.symbol} onClick={()=>handleOptionChangeSymbol(options.symbol)}
                ><div className='dropdown-itemNew cursor-pointer shadow-sm gap-2 hover:bg-sky-500 border  dropdown-content'>{options.symbol}</div>
              </div>
            ))}
            
       

            
              </div>}
              </div>
              <div className='py-1 mx-5 abc  '  >
                
              <div className='flex border rounded-md shadow-sm items-center justify-between  p-2 maxBalance editable-div '>
                
                    <div className=' border-none'  
          contentEditable  style={{outline: 'none' , direction: 'ltr',textAlign: 'right' }}    onInput={changeAmount}>{kaanchBalance || inputBalance}</div>
                    <div className='border shadow-md px-2 items-center rounded-md cursor-pointer' >max</div>
                    
                    
                </div> 
              </div>
              </div>



              <div className=' flex justify-between pt-2'>
                                <div className='flex '>
                                           
                                </div>
                                <div className='flex '>
                                    <div className='px-1'>{kaanchBalance}</div>
                                    <div>available</div>
                                </div>
                            </div>
          </div>
            
            

            
            
            ):(<div className=' text-xl flex-col mx-3 '>
              <div className='flex justify-between px-2 '>
                  <div>Source token</div>
                  {account?(<div className='text-xl text-red-500 borderConnect rounded-2xl px-4  cursor-pointer hover:text-red-900' onClick={disconnectFromMetaMask}>Disonnect</div>):(<div className='text-xl text-red-500 borderConnect rounded-2xl px-4 cursor-pointer hover:text-red-900 ' onClick={connectToMetaMask}>Connect</div>)}
              </div>

              <div className='flex '>
              <div className='py-1 '>
                
              <div className='flex border rounded-md shadow-sm items-center justify-between   p-2 dropdownNew'  >


                    <div className='flex dropdown-btnNew relative' onClick={()=>setisActivemetaToken(!isActivemetaToken)}   >
                    <div className=' w-5 h-5' >{account?(<div><img className=' rounded-full ' alt='' src={window.localStorage.getItem("secTokenImage")}/></div>):(<FaCircle />)}</div>
                    <div  className='px-2' >{ window.localStorage.getItem("secTokenValueSet")}</div>
                    </div>
                    <div ><IoMdArrowDropdown /></div>
                </div>

                {isActivemetaToken&&
            <div className='dropdown-contentNew cursor-pointer  absolute'>
            {tokenList.map((options)=>(
              <div key={options.symbol} onClick={() => handleOptionChangeSymbolMeta(options.symbol)}>
               
                <div className='dropdown-itemNew  cursor-pointer shadow-sm gap-2 hover:bg-sky-500 border '   >{options.symbol}</div>
              </div>
            ))}
            


            
              </div>}
              </div>
              <div className='py-1 mx-5 abc'>
                

                
                
                <div className='flex border rounded-md shadow-sm items-center justify-between  p-2 '>
                    
                    <div>0.0</div>
                    <div className='border shadow-md px-2 items-center rounded-md cursor-pointer'>max</div>
                    
                    
                </div>
                
                
                
              </div>
              </div>

              
              <div className=' flex justify-between pt-2'>
                                <div className='flex '>
                                           
                                </div>
                                <div className='flex '>
                                    <div className='px-1'>{0}</div>
                                    <div>available</div>
                                </div>
                            </div>
          
            </div>)}
          </div>
          {/*End of second div------------------------------------------------> */}


          {/*Sender Address start---------------------------------- */}
          <div className='text-xl border shadow-lg flex justify-between rounded-xl p-2 mt-2  box'>
            <div className='p-1'>Sender:</div>
            {!isClicked?(<div>
            <div className=''>{retrieved ? `${retrieved.substring(0, 4)}...${retrieved.substring(retrieved.length - 4)}` : 'Connect to Kaanch'}</div>
           </div>):(<div className=''>
            {account? `${account.substring(0, 4)}...${account.substring(account.length - 4)}`:<div></div>}
           </div>)}
            </div>

          {/*Sender Addresss end */}


        {/*sufficent or not sufficent */}
            {CanTransferBalance?(<div> {!isClicked?(<div className='boxSender cursor-pointer text-center  text-xl border shadow-lg flex bg-red-800 hover:bg-red-900  text-white justify-center  rounded-xl p-2 mt-2' onClick={transfer}>
            Kaanch to Other
          </div>):(<div className=' cursor-pointer text-center  text-xl border boxSender flex bg-red-800 hover:bg-red-900 text-white justify-center  rounded-xl p-2 mt-2'>
            Other to Kaanch
          </div>)}</div>):(<div><div className=' cursor-pointer text-center  text-xl border boxSender flex bg-slate-700 hover:bg-slate-800 text-white justify-center  rounded-xl p-2 mt-2'>
            Insufficient Balance
          </div></div>)}
       



        </div>
         {/**--------------------------- */}
        <div></div>

        <Footer/>
        </div>

       
        </div>

      
        </div>
    );
};

export default TransferKaanch;