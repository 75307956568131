
import './App.css';
import Header from "./compnent/Header";
import TransferKaanch from './compnent/TransferKaanch';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { ThemeProvider } from './ThemeContext';

import Footer from './compnent/Footer';
import Transferpage from './compnent/Transferpage';


function App() {
  return (
    <div  className=''>
         <ThemeProvider>

         <Router>
        <Header />
        <Routes>
          <Route path="/"  element={<Transferpage />}/>
          
        </Routes>
        
      </Router>
         </ThemeProvider>
        {/* <TransferKaanch/> */}
       
    </div>
  );
}

export default App;
