
import React, { useContext } from 'react';
import TransferKaanch from './TransferKaanch';
//<For Dark mode and white mode--------------------------------->
import { ThemeContext } from "../ThemeContext";
import "./Homepage.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import "./BlockdetailsPage.css";
import "bootstrap/dist/css/bootstrap.min.css";
const Transferpage = () => {
    const { toggleTheme, isDarkMode } = useContext(ThemeContext);
    return (
            <div className='w-full'>
            <TransferKaanch/>
            </div>
         
     
    );
};

export default Transferpage;