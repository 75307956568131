import React, { useContext } from "react";
import "./Header.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import { ThemeContext } from "../ThemeContext";
const Header = () => {
  const { toggleTheme, isDarkMode } = useContext(ThemeContext);
  const handleThemeToggle = () => {
    toggleTheme();
  };

  



 



 


    
  return (
    <div className="">
      <div
        className={`navbar-custom ${
          isDarkMode ? "dark-mode-header-page " : "light-mode-header-page "
        }`}
      >
       <div className="">
       <div className="container h-100 ">
          <div className="flex justify-between mt-3 h-100">
            <div className="col-auto">
              <span className="header-logo">
                <a href="/">Kaanch</a>
              </span>
            </div>
           
           
            {/* create-button-here  */}
            <div className="col-auto align-self-center">
              <div className="form-check form-switch">
                <input
                  className="form-check-input"
                  type="checkbox"
                  role="switch"
                  id="flexSwitchCheckDefault"
                  onChange={handleThemeToggle}
                  checked={isDarkMode}
                />
                <label
                  className="form-check-label ms-2 toggle-mode"
                  htmlFor="flexSwitchCheckDefault"
                >
                </label>
              </div>
            </div>
          </div>
        </div>
       </div>
      </div>
    </div>
  );
};

export default Header;
